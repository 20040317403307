<template>
  <div class="box">
    <div class="headline">岗位与职能维护</div>
    <div class="content">
      <p>进入岗位与职能维护界面，【系统设置】→【岗位与职能维护】，如下图</p>
      <img src="../../assets/specificationImgs/img25.png" alt width="100%" />
      <p>该图显示的是所有岗位与职能所对应的关系。</p>
      <p>
        1）新增：选择《公司》，再选择《部门》，然后再选择《岗位》，最后再点击“添加职能”弹出如下图，可选择多个《职能名称》，最后点击“确定添加”完成岗位与职能关系的维护。<br />
        <img src="../../assets/specificationImgs/img26.png" alt width="100%" />
        2）修改：与新增步骤一致，选中同一个《岗位》后再选择多个《职能名称》，点击“确定添加”就是修改。<br />
        3）删除：点击列表“删除”即可删除《岗位》与《职能》的某一个关系。
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
